<template>
  <div class="layout_common roles_manage">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <inputComp
              :hval="storeName"
              @blur="storeNameBlur"
              placeholder="商家名"
            />
            <dateComp :hval="date" @change="dateChange" />
            <btnGroup @search="search" @reset="reset" />
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          v-loading="loading"
        >
        </tableComp>
      </div>
    </div>
  </div>
</template>
<script>
import { supplierList } from '@/api'
export default {
  data () {
    return {
      loading: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      storeName: '',
      startTime: '',
      endTime: '',
      date: [],
      tableData: [],
      theadName: [
        { prop: 'receiveExpCount', label: '接收快递数' },
        { prop: 'storeName', label: '商家', width: 240 },
        { prop: 'serviceStationCount', label: '服务站数' },
        { prop: 'postSaleOrderCount', label: '远程工单数' },
        { prop: 'visitOrderCount', label: '上门工单数' },
        { prop: 'equipmentCount', label: '报障设备数' },
        { prop: 'sendExpCount', label: '补发快递数' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        storeName: this.storeName,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.loading = true
      supplierList(params).then((res) => {
        this.loading = false
        this.tableData = res.data.list
        this.total = res.data.totalCount
      })
    },
    storeNameBlur (val) {
      this.storeName = val
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.storeName = ''
      this.startTime = ''
      this.endTime = ''
      this.date = []
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
